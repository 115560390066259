<template>
    <!-- Public DashBoard -->
    <section class="blockElement space dashboardPortfolio mb-2 py-4">
        <div class="container-fluid">
            <!-- Header Total PNL and Value -->
            <div class="row mb-4 pb-2 g-0 mx-0 border-bottom">
                <div class="col-12 col-md-6 mb-2">
                    <div class="d-flex align-items-center">
                        <span class="me-3 imgContain rounded-circle border overflow-hidden" style="width: 35px; height: 35px;" v-if="store.customerDetail?.imageUrl"><v-lazy-image width="35" height="35" class="rounded-circle" :src="store.customerDetail.imageUrl+'?'+store.userProfileImage" /></span>
                        <span class="userIcon me-3 ms-0" v-else>{{(store.userDetail?.firstName) ? store.userDetail?.firstName.charAt(0) : ''}}{{(store.userDetail?.lastName) ? store.userDetail?.lastName.charAt(0) : ''}}</span>
                        <h5 class="mb-0" v-if="store.userDetail">{{(store.userDetail?.firstName) ? store.userDetail?.firstName : ''}} {{(store.userDetail?.lastName) ? store.userDetail?.lastName : ''}}</h5>
                    </div>
                </div>
                <div class="col-12 col-md-6 mb-2">
                    <div class="d-flex align-items-center justify-content-end">
                        <a :href="store.userSelectedAccount.depositFundsUrl" target="_blank" class="button py-1 ps-0 fillBtn zulu_btn small px-2 me-2" v-if="store.userSelectedAccount?.depositFundsUrl"> {{$t('expand_menu.text2')}}
                        </a>
                        <a :href="store.userSelectedAccount.withdrawFundsUrl" target="_blank" class="button py-1 zulu_btn rounded small px-2 border-button" v-if="store.userSelectedAccount?.withdrawFundsUrl">{{$t('expand_menu.text3')}}</a>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="pnl d-flex align-items-center flex-wrap">
                        <div class="d-flex align-items-center flex-wrap mobBlock me-md-3">
                            <p class="mb-0 me-2">{{$t('expand_menu.text1')}}:</p>
                            <div class="inColumn d-flex align-items-center">
                                <h6 class="semibold mb-0 me-2 secondary" v-if="COMMON.realTimeGrossPnl()">
                                    {{parseFloat(accountsTotal()+COMMON.realTimeGrossPnl()) >= 0 ? '' : '-'}}{{COMMON.getBaseCurrencySymbol() ? COMMON.getBaseCurrencySymbol() : '$'}}{{(parseFloat(Math.abs(accountsTotal()+COMMON.realTimeGrossPnl())).toFixed(2)+"").split(".")[0].toLocaleString()}}<sub class="f-14">.{{(parseFloat(accountsTotal()+COMMON.realTimeGrossPnl()).toFixed(2) + "").split(".")[1]}}</sub></h6>
                                <h6 class="semibold mb-0 me-2 secondary" v-else-if="store.userDashboardDetail?.equity">
                                    {{store.userDashboardDetail.equity >= 0 ? '' : '-'}}{{COMMON.getBaseCurrencySymbol() ? COMMON.getBaseCurrencySymbol() : '$'}}{{store.userDashboardDetail.equity ? (Math.abs(store.userDashboardDetail.equity.toFixed(2)) + "").split(".")[0].toLocaleString() : 0}}<sub class="f-14">.{{(((store.userDashboardDetail.equity).toFixed(2)) + "").split(".")[1]}}</sub>
                                </h6>
                                <h6 class="semibold mb-0 me-2 secondary" v-else>{{COMMON.getBaseCurrencySymbol() ? COMMON.getBaseCurrencySymbol() : '$'}}0</h6>
                                <div class="tooltipbutton mt-0 d-flex">
                                    <span class="tooltiptext f-12 creditBalance m-0">
                                        <p class="mb-0 line-4 f-14 border-bottom p-2 flex-between">Balance : <span>{{COMMON.getBaseCurrencySymbol() ? COMMON.getBaseCurrencySymbol() : '$'}}{{store.userDashboardDetail?.balance || 0}}</span></p>
                                        <p class="mb-0 line-4 f-14 border-bottom p-2 flex-between">Credit : <span>{{COMMON.getBaseCurrencySymbol() ? COMMON.getBaseCurrencySymbol() : '$'}}{{store.userDashboardDetail?.credit || 0}}</span></p>
                                        <p class="mb-0 line-4 f-14 p-2 flex-between" v-if="COMMON.realTimeGrossPnl()">Equity : <span class="secondary">{{parseFloat(accountsTotal()+COMMON.realTimeGrossPnl()) >= 0 ? '' : '-'}}{{COMMON.getBaseCurrencySymbol() ? COMMON.getBaseCurrencySymbol() : '$'}}{{(parseFloat(Math.abs(accountsTotal()+COMMON.realTimeGrossPnl())).toFixed(2)+"").split(".")[0].toLocaleString()}}<sub class="f-14">.{{(parseFloat(accountsTotal()+COMMON.realTimeGrossPnl()).toFixed(2) + "").split(".")[1]}}</sub></span></p>
                                        <p class="mb-0 line-4 f-14 p-2 flex-between" v-else-if="store.userDashboardDetail?.equity">Equity : <span class="secondary">{{store.userDashboardDetail.equity >= 0 ? '' : '-'}}{{COMMON.getBaseCurrencySymbol() ? COMMON.getBaseCurrencySymbol() : '$'}}{{store.userDashboardDetail.equity ? (Math.abs(store.userDashboardDetail.equity.toFixed(2)) + "").split(".")[0].toLocaleString() : 0}}<sub class="f-14">.{{(((store.userDashboardDetail.equity).toFixed(2)) + "").split(".")[1]}}</sub></span></p>

                                        <p class="mb-0 line-4 f-14 p-2 flex-between" v-else>Equity : <span class="secondary">{{COMMON.getBaseCurrencySymbol() ? COMMON.getBaseCurrencySymbol() : '$'}}0</span></p>
                                    </span>
                                    <p class="f-12 d-flex align-items-center justify-content-center mb-0"> <vue-feather class="smallSize" type="help-circle"></vue-feather></p>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex align-items-center mobBlock">
                            <p class="mb-0">{{$t('expand_menu.text10')}}:</p>
                            <div class="d-flex align-items-center">
                                <h6 class="mb-0 mx-1 secondary semibold" v-if="store.userDashboardDetail">{{parseFloat(store.userDashboardDetail.totalPnl+(COMMON.realTimeGrossPnl() || 0)) >= 0 ? '' : '-'}}{{COMMON.getBaseCurrencySymbol() ? COMMON.getBaseCurrencySymbol() : '$'}}{{((store.userDashboardDetail.totalPnl-store.userDashboardDetail.floatingPnl)+COMMON.realTimeGrossPnl()) ? parseFloat(Math.abs((store.userDashboardDetail.totalPnl-store.userDashboardDetail.floatingPnl)+(COMMON.realTimeGrossPnl() || 0))).toFixed(2) : 0}}</h6>
                                <h6 class="mb-0 px-1 f-14" :class="parseFloat(COMMON.realTimeGrossROI()) >= 0 ? 'greenView' : 'redView'">{{parseFloat(COMMON.realTimeGrossROI() || 0) > 0.0 ? '+' : ''}} {{(COMMON.realTimeGrossROI() || 0) ? parseFloat(COMMON.realTimeGrossROI() || 0).toFixed(2) : 0}}%</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    
                    <div class="element d-flex align-items-center justify-content-end mobStart">
                        <div class="element d-flex align-items-center me-3">
                            <p class="mb-0 me-2">{{$t('expand_menu.text6')}}:</p>
                            <p class="semibold mb-0 secondary">{{(Object.keys(store.copyTraders) && store.copyTraders.providers) ? (store.userSelectedAccount?.isLeader && store.userSelectedAccount?.providerStatus == 'APPROVED') ? getLeaderCopiers() : getAccountWiseCopyLeaders.length : 0}}</p>
                        </div>
                        <div class="element d-flex align-items-center">
                            <p class="mb-0 me-2">{{$t('expand_menu.text7')}}:</p>
                            <p class="semibold mb-0 secondary">{{(store.userSelectedAccount?.isLeader && store.userSelectedAccount?.providerStatus == 'APPROVED') ? getLeaderFollowers() : getWatchListCount()}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End -->
            <!-- Non-Leader DashBoard -->
            <div class="row" v-if="!isLeaderAccount()">
                <div class="col-12">
                    <copyLeaders />
                </div>
            </div>
            <!-- Leader DashBoard -->
            <div class="row" v-if="isLeaderAccount()">
                <div class="col-12 mb-3 mb-md-4 mt-2">
                    <div class="addBG newFillTabs ">
                        <ul id="tabs-nav" class="zuluTabs d-flex align-items-center px-0 mb-0">
                            <li :class="[{ 'active': Tab == 2 }]"><a class="f-16" href="javascript:void(0)" @click.prevent="changeTab(2)">{{$t('leader_profile.portfolio')}}</a></li>
                            <li :class="[{ 'active': Tab == 1 }]"><a class="f-16" href="javascript:void(0)" @click.prevent="changeTab(1)">{{$t('leader_profile.overview')}}</a></li>
                            <li :class="[{ 'active': Tab == 3 }]"><a class="f-16" href="javascript:void(0)" @click.prevent="changeTab(3)">{{$t('leader_profile.advancedStats')}}</a></li>
                            <li :class="[{ 'active': Tab == 4 }]"><a class="f-16" href="javascript:void(0)" @click.prevent="changeTab(4)">{{$t('leader_profile.copiers')}}</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-xl-8 mb-4 mb-xl-0 isLeaderAccount">
                    <Stats ref="Leaderstats" />
                    <!-- <LeadersCopy /> -->
                </div>
                <div class="col-12 col-xl-4">
                    <Social v-if="Tab != 2" />
                    <Market :locate="'body'" v-if="Tab == 2" />
                </div>
            </div>
        </div>
    </section>
    <!-- End -->
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import $ from "jquery";
    //import PriceFeed from "@/store/stomp";
    import Market from "./pages/without-leader/markets";
    import Stats from "./pages/leader/stats";
    import Social from "@/views/trader/social/social.vue";
    import copyLeaders from "./pages/without-leader/copyTrade";
    // import LeadersCopy from "./pages/leader/copyTrade_leader.vue";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                providerClass: -1,
                openDrop: "",
                Tab: 2,
                chartData: [],
                tradeDetail: "",
                listTradeDetail: [],
                showTradeDetailPop: -1,
                tradeLoader: false,
                page: 1,
                limit: 10,
            };
        },
        components: {
            copyLeaders, 
            Market ,
            Stats,
            Social,
        },
        watch: {
            "store.userSelectedAccount"(){
                this.providerClass = -1
            },
            'store.allTypeAccount'(){
                if(this.store.allTypeAccount){ this.providerClass = -1 }
            },
        },
        computed: {
            getAccountWiseCopyLeaders() {
                if (Object.keys(this.store.copyTraders).length && this.store.copyTraders.providers && this.store.copyTraders.providers.length) {
                    let data = [];
                    if (this.store.allTypeAccount) {
                        if (this.store.allTypeAccount == "ALL_LIVE") {
                            data = this.store.copyTraders.providers.filter((i) => i.demo === false);
                        } else if (this.store.allTypeAccount == "ALL_DEMO") {
                            data = this.store.copyTraders.providers.filter((i) => i.demo === true);
                        }
                    } else {
                        if (Object.keys(this.store.userSelectedAccount).length) {
                            data = this.store.copyTraders.providers.filter((i) => i.zuluAccountId == this.store.userSelectedAccount.zuluAccountId);
                        } else {
                            data = [];
                        }
                    }
                    return data;
                } else {
                    return [];
                }
            },
        },
        methods: {
            getLeaderCopiers(){
                if(this.store.traderDetail?.trader?.stats?.overallStats?.followers){
                    return this.store.traderDetail?.trader?.stats?.overallStats?.followers
                }else{
                    return 0
                }
            },
            getLeaderFollowers(){
                if(this.store.traderDetail?.trader?.stats?.overallStats?.includedInWatchlist){
                    return this.store.traderDetail?.trader?.stats?.overallStats?.includedInWatchlist
                }else{
                    return 0
                }
            },
            changeTab(val) {
                this.Tab = val;
                this.providerClass = -1
            },
            isLeaderAccount(){
                if(this.store.allTypeAccount == 'ALL_LIVE'){
                    // let list = this.store.userTradingAccountsList.filter(i=>(i.demo === false && i.isLeader && i.providerStatus=='APPROVED' && i.connectivityStatus == 'Connected'))
                    let list = this.store.userTradingAccountsList.filter(i => i.demo === false && i.connectivityStatus == 'Connected');
                    if(list?.length){
                        if(list?.[0]?.providerId)
                            return true
                        else
                            return false
                    } else {
                        return false
                    }
                } else if(Object.keys(this.store.userSelectedAccount).length){
                    if(this.store.userSelectedAccount.isLeader && this.store.userSelectedAccount.providerStatus=='APPROVED' && this.store.userSelectedAccount.connectivityStatus == 'Connected'){
                        return true
                    }else{
                        return false
                    }
                } else {
                    return false
                }
            },
            accountsTotal(){
                if(Object.keys(this.store.userDashboardDetail).length){
                    return parseFloat(this.store.userDashboardDetail.equity-this.store.userDashboardDetail.floatingPnl)
                }else{
                    return 0
                }
            },
            getWatchListCount() {
                if (this.store.leaderWatchList.length) {
                    let data = this.store.leaderWatchList.filter((i) => i.type == "LEADER");
                    if (data.length) {
                        return data.length;
                    } else {
                        return 0;
                    }
                } else {
                    return 0;
                }
            },
        },
        mounted() {
            let _that = this;
            $(document).on("click", function (event) {
                if (!$(event.target).closest(".showClass").length) {
                    _that.openDrop = "";
                }
            });
        },
        created() {
            // this.store.callCustomerDetail({}, false)
        },
    };
</script>
