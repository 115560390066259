<template>
   
    <div id="advancedSearch"  class="text-end insideGraph">
        <div class="advancedMenu">
            <a href="javascript:void(0)" class="selectDropdown f-14 min-auto bg-white d-flex align-items-center justify-content-between" @click="trading = !trading" style="width:210px">{{tradinfFilter}}<i class="ms-2 fa fa-caret-down" aria-hidden="true"></i></a>
            <ul class="dropdown_menu_animated week py-2" :class="[{'show':trading}]">
                <li v-for="value,key in static_vars.tradingPairsFilterJson" :key="key">
                    <a @click="tradinfFilter=value;trading=false;getTradingData()" :class="[{'active':tradinfFilter == value}]" href="javascript:void(0)">{{value}}</a>
                </li>
            </ul>
        </div>
    </div>
     <div id="tradingPairsDashboardChart" class="tradingPairsChart" v-show="Object.keys(store.tradingPairsChart).length && store.tradingPairsChart.series && store.tradingPairsChart.series.length && store.tradingPairsChart.series[0].data.length"></div>
     <Nodata v-if="!store.singleLoading && Object.keys(store.tradingPairsChart).length && store.tradingPairsChart.series && store.tradingPairsChart.series.length && store.tradingPairsChart.series[0].data.length == 0"></Nodata>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import { myStore } from "@/store/pinia-store";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {    
        return {
            tradinfFilter : 'Pairs',
            trading : false
        }
    },
    watch : {
        '$parent.$parent.dateDropSelected'(){
            this.getTradingData()
        },
    },
    methods: {
        drawTradingChartData(){
                let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'tradingPairsDashboardChart');
                d?.dispose();
                var root = am5.Root.new("tradingPairsDashboardChart");
                root.setThemes([
                    am5themes_Animated.new(root),
                    am5themes_Responsive.new(root)
                ]);

                // Create chart
                var chart = root.container.children.push(am5percent.PieChart.new(root, {
                    layout: root.verticalLayout
                }));

                var series = chart.series.push(am5percent.PieSeries.new(root, {
                    valueField: "y",
                    categoryField: "x",
                }));
                series.get("colors").set("colors", [
                    am5.color(0x4572a7),
                    am5.color(0xCBCBCB),
                    am5.color(0x69cd4b),
                    am5.color(0xff6f34),
                    am5.color(0xf6f819),
                    am5.color(0x7dfe8b),
                    am5.color(0xffaf6e),
                    am5.color(0xfff263),
                    am5.color(0xff7200),
                ]);
                series.data.setAll(this.store.tradingPairsChart.series[0].data);
                series.slices.template.set("tooltipText", "{x}: {y}");
                series.slices.template.set("toggleKey", "none");
                series.labels.template.setAll({
                    text: "{x}",
                    radius: 10,
                    fontSize: 12,
                    fontWeight: "600",
                });
                series.appear(1000, 100);
        },
        getProviderId(){
            if(this.store.userTradingAccountsList.length){
                if(this.store.allTypeAccount == 'ALL_LIVE'){
                    let LiveAccs = this.store.userTradingAccountsList.filter(i=>(i.demo === false && i.isLeader && i.providerStatus=='APPROVED' && i.connectivityStatus == 'Connected'));
                    if(LiveAccs?.length && LiveAccs?.[0]?.providerId){
                        return LiveAccs?.[0]?.providerId
                    }
                }else if(this.store.allTypeAccount == 'ALL_DEMO'){
                    let demoAccs = this.store.userTradingAccountsList.filter(i=>(i.demo === true && i.isLeader && i.providerStatus=='APPROVED' && i.connectivityStatus == 'Connected'));
                    if(demoAccs?.length && demoAccs?.[0]?.providerId){
                        return demoAccs?.[0]?.providerId
                    }
                }   
            }
        },
        getTradingData(){
            let formData = {};
            let type = ''
            if(this.tradinfFilter == 'Pairs'){
                type = 'CURRENCY_COUNT'
            }else {
                type = 'CURRENCY_TIMEZONE'
            }
            this.store.callTradingPairsChartData(formData,true,(this.store.allTypeAccount) ? this.getProviderId() : this.store.userSelectedAccount.providerId,this.$parent.$parent.dateDropSelected.key,type).then(() =>{
                if(Object.keys(this.store.tradingPairsChart).length && this.store.tradingPairsChart.series && this.store.tradingPairsChart.series.length > 0)
                    am5.ready(() => {
                        this.drawTradingChartData()
                    });
            })
        }
    },
    mounted() {
        this.getTradingData()
    },
}
</script>
<style>
    .tradingPairsChart{
        height: 400px;
        width: 100%;
    }
</style>